<template>
  <v-container fluid style="margin-bottom: 100px" ref="top">
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="11">
        <v-row>
          <v-col class="d-flex flex-column align-start">
            <h1 class="secondary--text">
              {{ `${ isEdit ? 'Editar' : isCopy ? 'Cópiar' : ''} Configurações de Transformações do Layout` }}
            </h1>
          </v-col>
          <v-col cols="12" md="5" class="py-0">
            <v-stepper value="2" style="background-color: var(--v-background-base); box-shadow: none !important;" class="stepper">
              <v-stepper-header>
                <v-stepper-step complete step="1">Layout</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">
                  Transformações <br>e Mapa de Campo
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-10">
      <v-col cols="12" md="11" class="px-0">
        <v-form ref="formValidation">
          <HeaderConfigurations @isEditControll="isEditControll" @isCopyControll="isCopyControll" />
        </v-form>
      </v-col>
    </v-row>

    <v-form ref="formTransformations">
      <v-row justify="center">
        <v-col cols="12" md="11" class="px-0">
          <TransformationTable
            :propsLayout="layout"
            :propsFromToFields="fromToFields"
            :isEdit="isEdit"
            :isCopy="isCopy"
            :isImport="true"
            @scrollComponent="scrollComponent"
            @overlayControll="overlayControll" />
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120" center>
        <v-row class="justify-center">
          <v-col align="center">
            Processando...
          </v-col>
        </v-row>
      </v-progress-circular>
    </v-overlay>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <div slot="textInfo">
        <v-row v-if="transformationsOrderEmpty && transformationsOrderEmpty.length > 0">
          <v-col cols="12">
            Por favor preecha as ordens das transformações abaixo:
            <div v-for="(transformationEmpty, index) in transformationsOrderEmpty" :key="index">
              <h5 class="red--text" style="word-break: break-word">{{ `Coluna: [${transformationEmpty.column}] - Transformação: [${transformationEmpty.transformation}]` }}</h5>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="parametersValueEmpty && parametersValueEmpty.length > 0" :class="transformationsOrderEmpty && transformationsOrderEmpty.length > 0 ? 'mt-8' : ''" >
          <v-col cols="12">
            Por favor preecha os valores dos parâmetros abaixo:
            <div v-for="(transformationParameterValueEmpty, index) in parametersValueEmpty" :key="index">
              <h5 class="red--text" style="word-break: break-word">{{ `Coluna: [${transformationParameterValueEmpty.column}] - Transformação: [${transformationParameterValueEmpty.transformation}] - Parâmetro: [${transformationParameterValueEmpty.parameter}]` }}</h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </DynamicDialogInformation>

    <Footer
      @voltar="onClickBack"
      @cancelar="onClickCancel"
      @salvar="onClickSave"
      saveButtonLabel="Concluir"
    />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import Footer from '@/components/Footer/Footer.vue';
import TransformationTable from '@/components/Layout/Transformations/Table/transformationTable.vue';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import LayoutService from '@/services-http/sdi/LayoutService';
import HeaderConfigurations from '@/components/Layout/ImportSettingsHeaders/headerConfigurations.vue';
import lodash from 'lodash';

export default ({
  components: {
    Footer,
    DynamicDialogInformation,
    TransformationTable,
    HeaderConfigurations,
  },

  data: () => ({
    fromToFields: [],
    transformationsOrderEmpty: [],
    parametersValueEmpty: [],

    isEdit: false,
    isCopy: false,
    overlay: true,
    widthModal: 800,
    heightCard: 800,

    layoutType: {},
    layout: {},
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  mounted() {
    this.scrollTo();
    this.loadSessionStorage();
    this.loadField();
  },

  methods: {
    loadSessionStorage() {
      this.layoutType = JSON.parse(sessionStorage.getItem('layoutType'));
      this.layout = JSON.parse(sessionStorage.getItem('layout'));

      this.layout.columns.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    loadField() {
      this.dataSourceService.FindAll().then((response) => {
        let movementFilter = [];
        if (response && response.data) {
          movementFilter = response.data.find((item) => (this.layout.typeIntegrationValue === 'PAYROLL' ? item.value === 'recebimento_folha' : item.value === 'registro_movimentacao')).columns;
          this.fromToFields = movementFilter;
          this.fromToFields.sort((a, b) => (a.label > b.label ? 1 : -1));
        }
      });
    },
    onClickBack() {
      sessionStorage.setItem('layout', JSON.stringify(this.layout));
      this.redirectRouter('back');
    },
    onClickCancel() {
      this.redirectRouter('cancel');
    },
    async onClickSave() {
      if (this.$refs.formValidation.validate()) {
        if (this.$refs.formTransformations.validate()) {
          this.overlay = true;
          await this.captureIsCheckedItems();
        } else {
          this.overlay = false;
          this.customizeSnackbarMessage('error', 'Verifique os dados das colunas do layout');
        }
      } else {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Verifique os campos obrigatórios de configurações do layout');
      }
    },
    redirectRouter(type) {
      if (type === 'save' || type === 'cancel') {
        this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImports', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImports' });
      } else if (type === 'back') {
        if (this.isEdit) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnEditConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportColumnEditConfigs' });
        } else if (this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnCopyConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportColumnCopyConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportColumnConfigs', query: { financialGroupId: this.layout.financialGroupId } } : { name: 'LayoutImportColumnConfigs' });
        }
      }
    },
    async captureIsCheckedItems() {
      this.listTransformationsAux = [];
      const layoutColumnsModify = lodash.cloneDeep(this.layout.columns);
      layoutColumnsModify.forEach((element, columnIndex) => {
        element.transformations.forEach((item, transformationIndex) => {
          if (item && item.isChecked === true) {
            this.listTransformationsAux.push({
              ...item,
              columnIndex,
              transformationIndex,
            });
          }
        });
      });
      await this.addRealTransformationInColumns(layoutColumnsModify);
    },
    async addRealTransformationInColumns(layoutColumnsModify) {
      this.listTransformationsAux.forEach((element) => {
        const layoutColumnIndex = layoutColumnsModify.findIndex((item, index) => element.columnIndex === index);
        const layoutColumnTransformationIndex = layoutColumnsModify[layoutColumnIndex].transformations.findIndex((item, index) => element.transformationIndex === index);
        // eslint-disable-next-line no-param-reassign
        layoutColumnsModify[layoutColumnIndex].transformations[layoutColumnTransformationIndex] = element;
      });
      await this.separateIsCheckedInTransformations(layoutColumnsModify);
    },
    async separateIsCheckedInTransformations(layoutColumnsModify) {
      const allLayoutColumnsAux = lodash.cloneDeep(layoutColumnsModify);
      allLayoutColumnsAux.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        delete element.id;
        // eslint-disable-next-line no-param-reassign
        delete element.idLayout;
        // eslint-disable-next-line no-param-reassign
        element.transformations = element.transformations.filter((item) => item.isChecked).map((t) => ({
          column: t.id,
          name: t.name,
          order: t.order ? Number(t.order) : '',
          parameters: t.parameters,
          template: t.template,
          field: t.field,
        }));
      });
      const allColumns = await lodash.cloneDeep(allLayoutColumnsAux);
      this.verifyParameters(allColumns);
    },
    verifyParameters(allLayoutColumnsAux) {
      this.transformationsOrderEmpty = [];
      this.parametersValueEmpty = [];
      allLayoutColumnsAux.forEach((column) => {
        column.transformations.forEach((transformation) => {
          if (transformation.order === '') {
            this.transformationsOrderEmpty.push({
              transformation: transformation.name,
              column: column.name,
            });
          }
          if (transformation.name === 'Depara' && transformation.parameters && transformation.parameters.length < 4) {
            this.parametersValueEmpty.push({
              transformation: transformation.name,
              parameter: 'Não há parâmetros suficientes',
              column: column.name,
            });
          }
          if (transformation.parameters && transformation.parameters.length > 0) {
            transformation.parameters.forEach((parameter) => {
              const existingValue = 'value' in parameter;
              if (transformation.template !== 'concatenar_campos' && (!existingValue || (parameter.name !== 'SENAO' && parameter.value === ''))) {
                if (parameter.name !== 'Campo' && ((transformation.template !== 'replace_all' || transformation.template !== 'regex') && parameter.order !== 3)) {
                  this.parametersValueEmpty.push({
                    transformation: transformation.name,
                    parameter: parameter.name,
                    column: column.name,
                  });
                }
              }
              if (parameter.name === 'Campo') {
                // eslint-disable-next-line no-param-reassign
                parameter.name = column.name;
              }
              if ((transformation.template === 'replace_all' || transformation.template === 'regex') && parameter.order === 3) {
                // eslint-disable-next-line no-param-reassign
                parameter.value = parameter.value ? parameter.value : '';
              }
            });
          } else {
            transformation.parameters.push({
              isAlphanumeric: false,
              isField: true,
              order: 1,
              name: column.name,
              value: column.name,
            });
          }
        });
      });

      if (this.transformationsOrderEmpty.length === 0 && this.parametersValueEmpty.length === 0) {
        this.allLayoutColumns = lodash.cloneDeep(allLayoutColumnsAux);
        this.payloadSubmited();
      } else {
        this.$refs.DynamicDialogInformation.open();
        this.overlay = false;
      }
    },
    async payloadSubmited() {
      const layout = lodash.cloneDeep(this.layout);
      layout.isModel = false;
      layout.columns = this.allLayoutColumns;
      await this.deleteIdsForNewLayout(layout);
      if (this.isEdit) {
        this.isEditLayoutImport(layout);
      } else {
        this.isSaveAndCopyLayoutImport(layout);
      }
    },
    async deleteIdsForNewLayout(layout) {
      // eslint-disable-next-line no-param-reassign
      delete layout.queryTransformation;
      if (layout.tags.length > 0) {
        layout.tags.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          delete element.id;
        });
      }

      if (layout.columns.length > 0) {
        layout.columns.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          delete element.id;
          // eslint-disable-next-line no-param-reassign
          delete element.idLayout;

          if (element.transformations.length > 0) {
            element.transformations.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              delete item.id;
              item.parameters.forEach((data) => {
                // eslint-disable-next-line no-param-reassign
                delete data.id;
              });
            });
          }
        });
      }
    },
    isSaveAndCopyLayoutImport(layout) {
      // eslint-disable-next-line no-param-reassign
      delete layout.id;
      this.layoutService.Save(layout).then(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Layout cadastrado com sucesso!');
        setTimeout(() => {
          this.redirectRouter('save');
        }, 250);
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao criar o modelo de layout, tente novamente!');
      });
    },
    isEditLayoutImport(layout) {
      this.layoutService.Update(layout.id, layout).then(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Modelo editado com sucesso!');
        setTimeout(() => {
          this.redirectRouter('save');
        }, 250);
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao editar o modelo de layout, tente novamente!');
      });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    overlayControll(data) {
      this.overlay = data;
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    isEditControll(data) {
      this.isEdit = data;
    },
    isCopyControll(data) {
      this.isCopy = data;
    },
    scrollComponent(data) {
      if (data) {
        this.scrollTo();
      }
    },
  },

  created() {
    this.rule = new Rules();
    this.dataSourceService = new DataSourceService();
    this.layoutService = new LayoutService();
  },
});
</script>
